import {
  Bleed,
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Card,
  Divider,
  Grid,
  InlineGrid,
  InlineStack,
  Link,
  Page,
  Text,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import SkeletonLoader from "./SkeletonLoader";

export default function SubscriptionContentLoader() {
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
  const arr2 = [1, 2, 3];
  const { t } = useTranslation();
  return (
    <Page
      title={"Power Up Your Store With Premium Features"}
      subtitle={"Choose a pricing plan that meets your needs"}
      backAction={{ url: "#" }}
    >
      <BlockStack gap="400">
        <InlineStack align="end">
          <ButtonGroup variant="segmented">
            <Button
              pressed={true}
              disabled
            >
              Monthly
            </Button>
            <Button
              pressed={false}
              disabled
            >
              Yearly
            </Button>
          </ButtonGroup>
        </InlineStack>

        <div className="pricing-wrap">
          <Grid columns={{ xs: 1, sm: 1, md: 2, lg: 3 }}>
            {arr2.map((item, index) => (
              <Grid.Cell key={index}>
                <Card>
                  <BlockStack gap="400">
                    <InlineStack
                      gap="400"
                      align="space-between"
                    >
                      <BlockStack gap="400">
                        <SkeletonLoader
                          style={{ width: 100, height: 15 }}
                          lineHeight={0}
                        />
                        <SkeletonLoader
                          style={{ width: 80, height: 10 }}
                          lineHeight={0}
                        />
                      </BlockStack>
                      <SkeletonLoader
                        style={{ width: "60px", height: "20px" }}
                        lineHeight={0}
                      />
                    </InlineStack>
                    <Bleed marginInline="400">
                      <Divider />
                    </Bleed>
                    <BlockStack gap="100">
                      {arr.map((item, index) => (
                        <PlanFeatureItem key={index} />
                      ))}
                    </BlockStack>
                    <Bleed marginInline="400">
                      <Divider />
                    </Bleed>
                    <BlockStack gap="400">
                      <SkeletonLoader
                        style={{ width: 100, height: 15 }}
                        lineHeight={0}
                      />
                      <SkeletonLoader
                        style={{ width: 100, height: 15 }}
                        lineHeight={0}
                      />
                      <Button
                        variant="primary"
                        disabled
                        fullWidth
                      >
                        Choose
                      </Button>
                    </BlockStack>
                  </BlockStack>
                </Card>
              </Grid.Cell>
            ))}
          </Grid>
        </div>

        <Card>
          <BlockStack gap="400">
            <InlineStack
              gap="400"
              align="space-between"
            >
              <BlockStack gap="400">
                <SkeletonLoader
                  style={{ width: 100, height: 15 }}
                  lineHeight={0}
                />
                <SkeletonLoader
                  style={{ width: 80, height: 10 }}
                  lineHeight={0}
                />
              </BlockStack>
              <InlineStack
                gap="400"
                align="end"
                blockAlign="center"
              >
                <div style={{ display: "flex", flexDirection: "column", alignItems: "end", gap: 8 }}>
                  <SkeletonLoader
                    style={{ width: "100px", height: "20px" }}
                    lineHeight={0}
                  />
                  <SkeletonLoader
                    style={{ width: "200px", height: "10px" }}
                    lineHeight={0}
                  />
                </div>

                <Button
                  variant="primary"
                  disabled
                >
                  Choose
                </Button>
              </InlineStack>
            </InlineStack>
            <Bleed marginInline="400">
              <Divider />
            </Bleed>
            <BlockStack gap="100">
              <InlineGrid
                columns={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3 }}
                gap="100"
              >
                {arr.map((item, index) => (
                  <PlanFeatureItem key={index} />
                ))}
              </InlineGrid>
            </BlockStack>
            <Bleed marginInline="400">
              <Divider />
            </Bleed>
            <BlockStack gap="200">
              <InlineStack
                gap="200"
                wrap={false}
                blockAlign="center"
              >
                <SkeletonLoader
                  style={{ width: 100, height: 15 }}
                  lineHeight={0}
                />

                <SkeletonLoader
                  style={{ width: 100, height: 15 }}
                  lineHeight={0}
                />
              </InlineStack>
            </BlockStack>
          </BlockStack>
        </Card>

        <Text
          as="p"
          tone="subdued"
          alignment="center"
        >
          Alternatively
        </Text>

        <Card>
          <BlockStack gap="400">
            <InlineStack
              gap="400"
              align="space-between"
              blockAlign="center"
            >
              <BlockStack gap="400">
                <SkeletonLoader
                  style={{ width: 100, height: 15 }}
                  lineHeight={0}
                />
                <SkeletonLoader
                  style={{ width: 80, height: 10 }}
                  lineHeight={0}
                />
              </BlockStack>
              <Button
                variant="primary"
                disabled
              >
                Choose
              </Button>
            </InlineStack>
            <Bleed marginInline="400">
              <Divider />
            </Bleed>
            <BlockStack gap="100">
              <InlineGrid
                columns={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3 }}
                gap="100"
              >
                {arr.map((item, index) => (
                  <PlanFeatureItem key={index} />
                ))}
              </InlineGrid>
            </BlockStack>
          </BlockStack>
        </Card>

        <Text>
          <Link
            monochrome
            disabled
          >
            Click here
          </Link>{" "}
          {t("to")} <Text as="strong">cancel your subscription plan</Text>
        </Text>
      </BlockStack>
    </Page>
  );
}

const PlanFeatureItem = () => {
  return (
    <InlineStack
      gap="200"
      wrap={false}
      blockAlign="center"
    >
      <Box>
        <SkeletonLoader style={{ width: Math.floor(Math.random() * 101) + 100, height: 10 }} />
      </Box>
    </InlineStack>
  );
};
