import { Suspense } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { rootFlexStyle } from "../styles/common";
import DummyPageSkeleton from "../components/loader/DummyPageSkeleton";
import Footer from "../components/common/Footer";
import DummyProductFix from "../components/loader/DummyProductFix.jsx";
import SubscriptionContentLoader from "../components/loader/SubscriptionContentLoader.jsx";
import OnboardSkeleton from "../components/loader/OnboardSkeleton.jsx";

export default function DefaultLayout({ children }) {
  const { pathname } = useLocation();

  return (
    <div style={rootFlexStyle}>
      {/*<Header />*/}
      <div style={{ flex: 1 }}>
        <Suspense
          fallback={
            pathname.match(/(?:(?:products)|(?:pages)|(?:articles))\/[0-9].*/gim) ? (
              <DummyProductFix />
            ) : pathname.match(/subscription/gim) ? (
              <SubscriptionContentLoader />
            ) : pathname.match(/onboard/gim) ? (
              <OnboardSkeleton />
            ) : (
              <DummyPageSkeleton />
            )
          }
        >
          <Outlet />
        </Suspense>
      </div>
      <Footer />
    </div>
  );
}
