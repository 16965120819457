import { Link, Text } from "@shopify/polaris";
import React from "react";
import { useTranslation } from "react-i18next";
import config, { CHANGELOG_URL, STOREWARE_URL } from "../../config";
import { useAppNavigation } from "../../hooks/useAppNavigation";
import Logo from "./Logo.jsx";

const year = new Date().getFullYear();

const Footer = () => {
  const { t } = useTranslation();
  const { goToPath } = useAppNavigation();

  return (
    <footer className="footer">
      <div className="footer__logo">
        <Link url="/">
          <Logo
            width={150}
            height={38}
          />
        </Link>
      </div>
      <Text
        as={"p"}
        tone={"subdued"}
      >
        {t("Copyright")} © {year} |{" "}
        <Link
          removeUnderline
          monochrome
          url={STOREWARE_URL}
          target={"_blank"}
        >
          <Text
            as={"strong"}
            tone={"subdued"}
          >
            Storeware
          </Text>
        </Link>{" "}
        | {t("Version")}{" "}
        <Link
          removeUnderline
          monochrome
          url={CHANGELOG_URL}
          target={"_blank"}
        >
          <Text
            as={"strong"}
            tone={"subdued"}
          >
            {config.appVersion}
          </Text>
        </Link>
      </Text>
      <div
        className="footer-language d__flex align__center"
        style={{ zIndex: 99, width: "100px" }}
      >
        {/*<LanguageSwitcher />*/}
      </div>
    </footer>
  );
};

export default Footer;
