import { useState } from "react";

export default function useBannerPagination(banners) {
  const [bannersList, setBannersList] = useState(banners.slice(0, 3));
  const [pageNumber, setPageNumber] = useState(1);
  const totalPages = Math.ceil(banners.length / 3);

  const handlePageChange = (pageNumber) => {
    const startIndex = (pageNumber - 1) * 3;
    setBannersList(banners.slice(startIndex, startIndex + 3));
  };

  const handleNext = () => {
    if (pageNumber < totalPages) {
      const nextPage = pageNumber + 1;
      setPageNumber(nextPage);
      handlePageChange(nextPage);
    }
  };

  const handlePrev = () => {
    if (pageNumber > 1) {
      const prevPage = pageNumber - 1;
      setPageNumber(prevPage);
      handlePageChange(prevPage);
    }
  };

  return {
    bannersList,
    totalPages,
    pageNumber,
    handleNext,
    handlePrev,
  };
}
