import { PhoneIcon } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import { BANNER_SCHEDULE_A_CALL } from "storeseo-enums/cacheKeys";
import { SCHEDULE_A_CALL_URL } from "../../config";
import DismissableBanner from "../common/DismissableBanner";

const ScheduleACallCard = () => {
  const { t } = useTranslation();

  return (
    <DismissableBanner
      mediaCard
      title={t("Want to schedule a call?")}
      content={t(
        "We have an in-house SEO expert who can audit your store and guide you in enhancing its SEO. Would you like to book a one-on-one session with them? It’s completely FREE!"
      )}
      primaryAction={{
        content: t("Talk to SEO expert"),
        icon: PhoneIcon,
        url: SCHEDULE_A_CALL_URL,
        target: "_blank",
      }}
      illustration="/img/seo-expert.png"
      bannerKey={BANNER_SCHEDULE_A_CALL}
    />
  );
};

export default ScheduleACallCard;
