import { useTranslation } from "react-i18next";
import { BANNER_SEO_GUIDE } from "storeseo-enums/cacheKeys";
import { SEO_GUIDE_URL } from "../../config";
import DismissableBanner from "../common/DismissableBanner";

const SEOGuideCard = () => {
  const { t } = useTranslation();

  return (
    <DismissableBanner
      mediaCard
      title={t("Get our free Shopify SEO guide")}
      content={t("Grab your copy today & discover the secrets to optimizing your Shopify store for search engines")}
      primaryAction={{
        content: t("Get the Free eBook"),
        url: SEO_GUIDE_URL,
        target: "_blank",
      }}
      illustration="/img/book-cover.png"
      bannerKey={BANNER_SEO_GUIDE}
    />
  );
};

export default SEOGuideCard;
