export const partnerships = [
  {
    key: "pagefly",
    name: "PageFly Landing Page Builder",
    content: "Use PageFly Free to create high-converting Shopify stores with 100+ templates.",
    url: "https://pagef.ly/18b88v",
    btnText: "View app",
    logo: "/img/partnership-logos/pagefly.png",
  },
  {
    key: "consentik",
    name: "Consentik GDPR Cookies Banner",
    content: "Cookie banner, cookies consent for EU GDPR & CCPA cookie fully compliance with 24/7 cusomer support.",
    url: "https://storeseo.com/go/consentik-gdpr-cookies-banner",
    btnText: "View app",
    logo: "/img/partnership-logos/consentik.png",
  },
  {
    key: "toastibar",
    name: "ToastiBar - Sales Popup",
    content: "Build trust & boost sales by showing Sales, Cart, Visitors, Reviews, Summaries, and Inventory popups",
    url: "https://makeprosimp.com/partnership/storeware-toastibar",
    btnText: "View app",
    logo: "/img/partnership-logos/toastibar.avif",
  },
  {
    key: "aitrillion",
    name: "AiTrillion: Loyalty, Email, Review AI",
    content:
      "AiTrillion is a sales & marketing automation platform built to grow E-commerce via powerful modules to maximize customer engagement, and retention.",
    url: "https://storeseo.com/go/aitrillion",
    btnText: "View app",
    logo: "/img/partnership-logos/AiTrillion.png",
  },
  {
    key: "uppromote",
    name: "UpPromote Affiliate Marketing",
    content: "Complete, easy referral & affiliate program app, trusted by top brands & experts for a revenue boost.",
    url: "https://partners.secomapp.com/apps/affiliate/STORE_SEO_APP",
    btnText: "View app",
    logo: "/img/partnership-logos/uppromote.png",
  },
  {
    key: "foxify",
    name: "Foxify: Smart Page Builder",
    content:
      "80+ high-converting designs, built-in upsells, & advanced analytics to impact sales, costs, & profit all at once",
    url: "https://foxecom.com/products/foxify-shopify-app?utm_source=Storeware&utm_medium=InappDashboard&utm_campaign=partnership",
    btnText: "View app",
    logo: "/img/partnership-logos/foxify.png",
  },
  {
    key: "foxkit",
    name: "FoxKit - All‑in‑one Sales Boost",
    content:
      "Optimize conversion rates & sales with tempting upsells & cross-sells, engaging alerts, & advanced analytics.",
    url: "https://foxecom.com/products/foxkit-shopify-app?utm_source=Storeware&utm_medium=InappDashboard&utm_campaign=partnership",
    btnText: "View app",
    logo: "/img/partnership-logos/foxKit.png",
  },
  {
    key: "betterdocs",
    name: "BetterDocs",
    content: "BetterDocs knowledge base is the ultimate knowledge base solution to create FAQs and store.",
    url: "https://storeseo.com/go/get-betterdocs",
    btnText: "View app",
    logo: "/img/partnership-logos/betterdocs.png",
  },
  {
    key: "storefaq",
    name: "BetterFAQ‑ Product FAQ Builder",
    content:
      "Get the simplest Shopify FAQ Builder to answer your customer queries with interactive accordion tabs and reduce your support pressure.",
    url: "https://storeseo.com/go/storefaq",
    btnText: "View app",
    logo: "/img/partnership-logos/storefaq.png",
  },
  {
    key: "trustsync",
    name: "TrustSync",
    content: "Collect reviews for Google, Facebook, Etsy, Trustpilot, Yelp and more to boost your online reputation.",
    url: "https://storeseo.com/go/get-trustsync",
    btnText: "View app",
    logo: "/img/partnership-logos/trustsync.png",
  },
];
