import { Icon, InlineStack, Text } from "@shopify/polaris";
import { MagicIcon } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import { BANNER_AI_OPTIMIZER_PROMO } from "storeseo-enums/cacheKeys";
import useUserAddon from "../../hooks/useUserAddon";
import DismissableBanner from "../common/DismissableBanner";

export default function AiOptimizerPromoCard() {
  const { t } = useTranslation();
  const { hasAiOptimizer } = useUserAddon();

  if (hasAiOptimizer) return null;

  return (
    <DismissableBanner
      mediaCard
      title={
        <Text
          tone="magic"
          variant="headingSm"
        >
          <InlineStack
            gap="100"
            align="center"
          >
            <Icon
              source={MagicIcon}
              tone="textMagic"
            />
            {t("Introducing Our Latest AI SEO Optimization Feature  🎉")}
          </InlineStack>
        </Text>
      }
      content={t(
        "Don't know how to optimize your products for SEO? Or want to speed up your workflow? Try our AI Content Optimizer to make your Meta Titles, Meta Descriptions, and Tags SEO-friendly within the blink of an eye."
      )}
      primaryAction={{
        content: t("Check it out"),
        url: "/credit-bundles",
      }}
      illustration="/img/ai-optimizer-promo.png"
      bannerKey={BANNER_AI_OPTIMIZER_PROMO}
    />
  );
}
