import { useApiClient } from "../useApiClient.js";

export const useUserApi = () => {
  const apiClient = useApiClient();

  const getAuthUserData = async () => {
    try {
      return await apiClient("/auth");
    } catch (err) {
      console.error("getAuthUserData", err);
    }
  };

  return {
    getAuthUserData,
  };
};
