import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export function useAppNavigation() {
  const navigate = useNavigate();

  const goToProduct = (shopifyId) => navigate(`/optimize-seo/products/${shopifyId}`);
  const goToProductList = () => navigate(`/optimize-seo`);

  const goToPage = (shopifyId) => navigate(`/optimize-seo/pages/${shopifyId}`);
  const goToPageList = () => navigate(`/optimize-seo/pages`);

  const goToArticle = (shopifyId) => navigate(`/optimize-seo/articles/${shopifyId}`);
  const goToArticleList = () => navigate(`/optimize-seo/articles`);

  const goToPath = (path) => navigate(path);
  const goBack = () => navigate("/");

  const goToSubscription = () => navigate("/subscription");

  const goToCollection = (shopifyId) => navigate(`/optimize-seo/collections/${shopifyId}`);
  const goToCollectionList = () => navigate(`/optimize-seo/collections`);

  const backAction = useMemo(() => {
    return {
      onAction() {
        navigate(-1);
      },
    };
  }, [navigate]);

  const backToDashboardAction = {
    onAction() {
      navigate("/");
    },
  };

  return {
    goToProduct,
    goToProductList,
    goToPage,
    goToPageList,
    goToArticle,
    goToArticleList,
    goToPath,
    goBack,
    backAction,
    goToSubscription,
    goToCollection,
    goToCollectionList,
  };
}
