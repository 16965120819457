import { PRODUCT, PAGE, ARTICLE, COLLECTION } from "storeseo-enums/analysisEntityTypes";
import { useApiClient } from "../useApiClient";

const endpoints = {
  [PRODUCT]: "/ai/generate-product-content",
  [COLLECTION]: "/ai/generate-collection-content",
  [PAGE]: "/ai/generate-page-content",
  [ARTICLE]: "/ai/generate-article-content",
};

export const useAiContentApi = () => {
  const apiClient = useApiClient();

  /**
   * generates ai content based on the given params
   * @param {{title: string, description: string, focusKeyword: string, type: PRODUCT | PAGE | ARTICLE | COLLECTION}}
   */
  const generateAiContent = async ({ title, description, focusKeyword, type = PRODUCT }) => {
    try {
      return await apiClient(endpoints[type], {
        method: "POST",
        body: JSON.stringify({
          title,
          description,
          focusKeyword,
        }),
      });
    } catch (error) {
      throw await error.json();
    }
  };

  return {
    generateAiContent,
  };
};
