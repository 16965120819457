import { ActionList, BlockStack, Box, Button, Card, InlineStack, MediaCard, Popover, Text } from "@shopify/polaris";
import { XIcon, MenuHorizontalIcon, ReplayIcon } from "@shopify/polaris-icons";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useShopApi } from "../../hooks";
import { setHiddenBanner } from "../../store/features/HiddenBanner";
import { useTranslation } from "react-i18next";

const DismissableBanner = ({
  title,
  content,
  primaryAction,
  secondaryAction,
  bannerKey,
  illustration,
  mediaCard = false,
  mediaCardSize = "small",
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const shopApi = useShopApi();
  const [actionPopoverActive, setActionPopoverActive] = useState(false);
  const hiddenBanner = useSelector((state) => state.hiddenBanner);

  const isString = !React.isValidElement(content);

  const handleDismiss = async () => {
    dispatch(setHiddenBanner({ bannerKey, status: false }));
    shopApi.hideBanner(bannerKey);
  };

  const handleRemindMeLater = async () => {
    dispatch(setHiddenBanner({ bannerKey, status: false }));
    shopApi.hideBanner(bannerKey, 7);
  };

  const defaultDismissItems = [
    {
      content: t("Remind me later"),
      icon: ReplayIcon,
      onAction: handleRemindMeLater,
    },
    {
      content: t("Dismiss"),
      icon: XIcon,
      destructive: true,
      onAction: handleDismiss,
    },
  ];
  const [dismissItems, setDismissItems] = useState(defaultDismissItems);

  const toggleActionPopover = useCallback(
    () => setActionPopoverActive((actionPopoverActive) => !actionPopoverActive),
    []
  );

  if (!hiddenBanner[bannerKey]) return null;

  if (mediaCard) {
    return (
      <MediaCard
        size={mediaCardSize}
        title={title}
        description={content}
        primaryAction={primaryAction}
        popoverActions={dismissItems}
      >
        {illustration && (
          <img
            alt={title}
            width="100%"
            height="100%"
            style={{
              objectFit: "cover",
              objectPosition: "center",
            }}
            src={illustration}
          />
        )}
      </MediaCard>
    );
  }

  return (
    <Card>
      <BlockStack gap="200">
        <InlineStack
          wrap={false}
          align="space-between"
        >
          <Text
            variant="headingSm"
            as="h4"
          >
            {title}
          </Text>

          <Popover
            active={actionPopoverActive}
            activator={
              <Button
                icon={MenuHorizontalIcon}
                variant="tertiary"
                onClick={toggleActionPopover}
              ></Button>
            }
            autofocusTarget="first-node"
            onClose={toggleActionPopover}
            preferredAlignment="left"
          >
            <ActionList
              actionRole="menuitem"
              items={dismissItems}
            />
          </Popover>
        </InlineStack>

        <InlineStack
          wrap={false}
          gap={200}
          align="space-between"
        >
          <Box width={illustration ? "80%" : "100%"}>
            {isString ? (
              <Text
                as="p"
                tone="subdued"
              >
                {content}
              </Text>
            ) : (
              content
            )}
          </Box>

          {illustration && (
            <Box width="20%">
              <img
                width={120}
                src={illustration}
                alt={title}
              />
            </Box>
          )}
        </InlineStack>

        <InlineStack gap="200">
          {primaryAction && (
            <Button
              onClick={primaryAction?.onAction}
              icon={primaryAction?.icon}
              url={primaryAction?.url}
              variant={primaryAction?.variant}
              target={primaryAction?.target}
            >
              {primaryAction.content}
            </Button>
          )}
          {secondaryAction && (
            <Button
              onClick={secondaryAction?.onAction}
              icon={secondaryAction?.icon}
              url={secondaryAction?.url}
              target={secondaryAction?.target}
              variant="plain"
            >
              {secondaryAction.content}
            </Button>
          )}
        </InlineStack>
      </BlockStack>
    </Card>
  );
};

export default DismissableBanner;
